import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { BXIcon } from "src/components/BXUI/Icon";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { isValidGoogleMapsApiKey, sanitizeApiKey } from "src/utils/helpers";
import { useMapsApiLoader } from "../CustomGoogleMap/GoogleMaps.helper";

interface GoogleMapAutocompleteProps {
  onPlaceSelected?: (place: google.maps.places.PlaceResult) => void;
  onChange?: (place: google.maps.places.PlaceResult | null) => void;
  googlePlacesApiKey?: string;
  isApiLoaded?: boolean;
  borderRadius?: string;
  placeholder?: string;
  enableStartButton?: boolean;
  iconConfigStartIcon?: any;
  startIconColor?: string;
  fontSize?: string;
  referenceGoogleMap?: string;
  pageId?: string;
  info?: any;
  defaultValue?: string;
}

const GoogleMapAutocomplete = ({
  onPlaceSelected,
  onChange,
  googlePlacesApiKey,
  isApiLoaded,
  borderRadius = "4px",
  placeholder = "Enter an address",
  enableStartButton = false,
  iconConfigStartIcon,
  startIconColor = "#000000",
  fontSize = "14px",
  referenceGoogleMap,
  pageId,
  info,
  defaultValue,
}: GoogleMapAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");
  const [hasProcessedDefaultValue, setHasProcessedDefaultValue] = useState(false);
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const { useReplaceDataPlaceholdersUseSelector } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const localReferenceLatAndLngValues = referenceGoogleMap ? `{this.${referenceGoogleMap}.state}` : "";
  const boundsReference = referenceGoogleMap ? `{this.${referenceGoogleMap}.bounds}` : "";
  const resolvedLocalReferenceLatAndLngValues = useReplaceDataPlaceholdersUseSelector({
    queryString: localReferenceLatAndLngValues,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });
  const bounds = useReplaceDataPlaceholdersUseSelector({
    queryString: boundsReference,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });

  const validApiKey = useMemo(() => {
    const sanitizedKey = sanitizeApiKey(googlePlacesApiKey || "");
    return isValidGoogleMapsApiKey(sanitizedKey) ? sanitizedKey : "";
  }, [googlePlacesApiKey]);
  const { isLoaded: internalIsLoaded } = useMapsApiLoader(validApiKey);
  const apiIsLoaded = isApiLoaded !== undefined ? isApiLoaded : internalIsLoaded;

  const fetchPredictions = (value: string) => {
    if (!autocompleteService.current || !value) {
      setOptions([]);
      return;
    }

    const sw = new window.google.maps.LatLng(bounds.southWest.lat, bounds.southWest.lng);
    const ne = new window.google.maps.LatLng(bounds.northEast.lat, bounds.northEast.lng);

    const defaultBounds = bounds ? new window.google.maps.LatLngBounds(sw, ne) : undefined;

    autocompleteService.current.getPlacePredictions({ input: value, types: [], locationBias: defaultBounds }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setOptions(predictions);
      }
    });
  };

  const handlePlaceSelected = (prediction: google.maps.places.AutocompletePrediction) => {
    if (!placesService.current) return;

    placesService.current.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        setInputValue(place.formatted_address || "");
        onPlaceSelected?.(place);
        onChange?.(place);
      }
    });
  };

  useEffect(() => {
    if (apiIsLoaded && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement("div"));
    }
  }, [apiIsLoaded]);

  useEffect(() => {
    if (
      defaultValue &&
      apiIsLoaded &&
      window.google?.maps?.Geocoder &&
      !resolvedLocalReferenceLatAndLngValues &&
      !hasProcessedDefaultValue
    ) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: defaultValue }, (results, status) => {
        setHasProcessedDefaultValue(true);
        if (status === window.google.maps.GeocoderStatus.OK && results?.[0]) {
          const formattedAddress = results[0].formatted_address;
          setInputValue(formattedAddress);

          const placeResult: google.maps.places.PlaceResult = {
            formatted_address: formattedAddress,
            geometry: results[0].geometry,
            address_components: results[0].address_components,
            name: formattedAddress,
          };

          onPlaceSelected?.(placeResult);
          onChange?.(placeResult);
        } else if (inputValue === defaultValue) {
          // Only clear if input matches the invalid default value
          setInputValue("");
          onChange?.(null);
        }
      });
    }
  }, [defaultValue, apiIsLoaded, resolvedLocalReferenceLatAndLngValues, hasProcessedDefaultValue, inputValue]);

  // Handle reference map location changes
  useEffect(() => {
    if (resolvedLocalReferenceLatAndLngValues && apiIsLoaded && window.google?.maps?.Geocoder) {
      const lat = resolvedLocalReferenceLatAndLngValues.lat;
      const lng = resolvedLocalReferenceLatAndLngValues.lng;
      if (typeof lat === "number" && typeof lng === "number") {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results?.[0]) {
            const formattedAddress = results[0].formatted_address;
            setInputValue(formattedAddress);

            const placeResult: google.maps.places.PlaceResult = {
              formatted_address: formattedAddress,
              geometry: {
                location: new window.google.maps.LatLng(lat, lng),
              },
              address_components: results[0].address_components,
              name: formattedAddress,
            };

            onPlaceSelected?.(placeResult);
            onChange?.(placeResult);
          }
        });
      }
    }
  }, [resolvedLocalReferenceLatAndLngValues]);

  useEffect(() => {
    if (apiIsLoaded && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement("div"));
    }
  }, [apiIsLoaded]);

  if (!validApiKey) {
    return <div>Please provide a valid Google Maps API key</div>;
  }

  if (!apiIsLoaded) {
    return <></>;
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => (typeof option === "string" ? option : option.description)}
      filterOptions={x => x}
      autoComplete
      includeInputInList
      freeSolo
      inputValue={inputValue}
      onInputChange={(_, value) => {
        setInputValue(value || "");
        fetchPredictions(value || "");
      }}
      onChange={(_, value) => {
        if (value === null) {
          // Handle clear button click
          setInputValue("");
          setOptions([]);
          onChange?.(null);
        } else if (typeof value !== "string") {
          handlePlaceSelected(value);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: enableStartButton && (
              <InputAdornment position='start'>
                <BXIcon width='25px' height='25px' icon={iconConfigStartIcon?.icon} url={iconConfigStartIcon?.url} color={startIconColor} />
              </InputAdornment>
            ),
            sx: {
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              padding: "8px 12px 8px 40px",
              paddingRight: "12px",
              borderRadius: borderRadius,
              fontSize: fontSize,
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.place_id}>
          {option.description}
        </li>
      )}
      sx={{
        width: "100%",
        height: "100%",
        "& .MuiFormControl-root": {
          height: "100%",
        },
      }}
    />
  );
};

export default GoogleMapAutocomplete;
