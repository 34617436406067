import { FormControlLabel } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { BaseSwitch } from "./BaseSwitch";
import { CustomSwitchProps } from "./types";

export const CustomSwitch: FC<CustomSwitchProps> = ({
  switchAppearance,
  customIconOn,
  customIconOff,
  customAndroidIconOff,
  customAndroidIconOn,
  children,
  textTypo,
  format,
  formatString,
  config,
  __config,
  metaData,
  mapValuesObject,
  info,
  pageId,
  appId,
  renderId,
  wordwrap,
  defaultValue,
  onChange,
  label,
  "data-bx-key": dataBxKey,
  trackColor,
  labelPlacement,
  thumbColor,
  disableEffect,
  thumbSize,
  trackWidth,
  trackHeight,
  ...restProps
}) => {
  const isLoading = restProps.loading;
  const defaultChecked = defaultValue === true || (typeof defaultValue === "string" && defaultValue.toLowerCase() === "true");
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultValue]);

  const e = (event: any) => {
    if (isLoading) return;
    if (onChange) {
      onChange(event.target.checked, event);
      setChecked(event.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={
        <BaseSwitch
          switchAppearance={switchAppearance}
          trackColor={trackColor}
          thumbSize={thumbSize}
          trackWidth={trackWidth}
          trackHeight={trackHeight}
          customIconOn={customIconOn}
          customIconOff={customIconOff}
          customAndroidIconOn={customAndroidIconOn}
          customAndroidIconOff={customAndroidIconOff}
          thumbColor={thumbColor}
          disableEffect={disableEffect}
          {...restProps}
          disabled={restProps.disabled}
          checked={checked}
          edge='end'
          onChange={e}
          sx={{
            "& .MuiFormControlLabel-label": {
              marginLeft: "8px",
            },
          }}
        />
      }
      label={label}
      required={false}
      labelPlacement={labelPlacement}
    />
  );
};
