import { IconButton } from "@mui/material";
import { BaseSwitch } from "src/views/pages/BuildX/FormBuilder/components";
import { BXIcon } from "../Icon";

interface TableActionProps {
  tableAction?: {
    type?: "Icon Button" | "Switch";
    icon?: string;
    iconConfig?: {
      borderRadius?: string;
      iconSize?: number;
      borderColor?: string;
      iconColor?: string;
      borderWidth?: string;
      borderStyle?: "none" | "solid" | "dotted" | "dashed" | "double";
    };
    action?: {
      switchConfig?: Record<string, any>;
    };
  };
  onSelectRow?: (item: any) => void;
  executeChain: (e: React.SyntheticEvent) => void;
  switchValue?: string;
  disabled?: boolean;
  isLoading?: boolean;
  item: any;
}

function TableAction({ tableAction, onSelectRow, executeChain, switchValue, disabled, isLoading, item }: TableActionProps) {
  return (
    <>
      {tableAction?.type === "Icon Button" && (
        <IconButton
          onClick={(e: React.MouseEvent) => {
            onSelectRow?.(item);
            e.stopPropagation();
            executeChain(e);
          }}
          disabled={disabled}
          sx={{
            borderRadius: tableAction?.iconConfig?.borderRadius || "50%",
            borderColor: tableAction?.iconConfig?.borderColor || "transparent",
            borderWidth: tableAction?.iconConfig?.borderWidth || "0px",
            borderStyle: tableAction?.iconConfig?.borderStyle || "solid",
          }}
        >
          <BXIcon
            icon={tableAction?.icon}
            width={tableAction?.iconConfig?.iconSize || 16}
            height={tableAction?.iconConfig?.iconSize || 16}
            color={tableAction?.iconConfig?.iconColor || "currentColor"}
          />
        </IconButton>
      )}
      {tableAction?.type === "Switch" && (
        <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <BaseSwitch
            {...(tableAction?.action?.switchConfig || {})}
            defaultChecked={switchValue === "true"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              onSelectRow?.(item);
              executeChain(e);
            }}
            disableChanging={isLoading}
          />
        </div>
      )}
    </>
  );
}

export default TableAction;
