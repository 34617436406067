import { IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import _ from "lodash";
import { FC, useState } from "react";
import { ActionButton, useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { useWindowSize } from "src/hooks/useScreenSize";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { BXIcon } from "../Icon";
import Popover from "./Popover";
// import Popover from "@mui/material/Popover";

export const ItemActions: FC<{
  actions?: any[];
  onStateChange: (newState: string) => void;
  disabled?: boolean;
  item: any;
  views: any;
  isUserInput?: boolean;
  tableId: any;
  onSelectRow?: (object: any) => void;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  pageId?: any;
  queryKeys?: any[];
  viewName?: string;
  path?: string;
  dataEntry?: any;
  index?: number;
  columnConfig: any;
}> = ({
  actions: _actions,
  onStateChange,
  disabled,
  item,
  isUserInput,
  views,
  tableId,
  onSelectRow,
  closeModal,
  parentIds,
  queryKeys,
  pageId,
  viewName,
  __data = {},
  dataEntry,
  index,
  columnConfig,
  path,
}) => {
  const {
    ellipsisActionIcon,
    showEllipsisForActions = true,
    numberOfActionsToDisplayEllipsis = 3,
    showIconList = true,
    showLabelList = true,
    minWidth,
    maxWidth,
    fieldAlignment,
  } = columnConfig;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { palette } = useTheme();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [x, setX] = useState(2361);
  const [y, setY] = useState(453);

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false); // Close the popover
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left;
    const y = rect.top;
    setX(x);
    setY(y);
    setIsPopoverOpen(true); // Open the popover
    setAnchorEl(event.currentTarget);
  };
  const { width } = useWindowSize();

  const mobileBreakpoint = columnConfig?.mobileBreakpoint ? parseInt(columnConfig.mobileBreakpoint) : 768;
  const isMobile = width < mobileBreakpoint;
  const open = Boolean(anchorEl);

  const actions = _actions?.filter(action => {
    const condition = replaceDataPlaceholders({
      queryString: action?.visibilityCondition ?? action?.condition,
      item,
      viewsState,
      pageId,
      __data,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      index,
      env: currentApp?.env,
    });
    return !(!eval(condition) && !_.isEmpty(condition));
  });

  if (_.isArray(actions) && !_.isEmpty(actions))
    return (
      <>
        {!(columnConfig.showInPopoverOnMobile && isMobile) &&
          (showEllipsisForActions ? actions?.slice(0, numberOfActionsToDisplayEllipsis) : actions)?.map(action => (
            <PermissibleRender isAllowed key={action?.id} action={["VIEW"]} path={[path, "action", action.label].join(".")}>
              {({ permitted }) =>
                permitted && (
                  <ActionButton
                    path={[path, action.label].join(".")}
                    item={item}
                    viewName={viewName}
                    queryKeys={queryKeys}
                    pageId={pageId}
                    disabled={disabled || !permitted}
                    key={action.id}
                    _key={`${action.id}-${item.__id}`}
                    tableAction={{
                      action,
                      label: action?.label,
                      iconConfig: action?.iconConfig,
                      icon:
                        (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                          ? action?.iconConfig?.url
                          : action?.iconConfig?.icon) || action?.icon,
                      type: action?.componentType || "Icon Button",
                      dataEntry,
                      condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                    }}
                    onStateChange={onStateChange}
                    isUserInput={isUserInput}
                    views={views}
                    onSelectRow={onSelectRow}
                    tableId={tableId}
                    __data={__data}
                    closeModal={closeModal}
                    parentIds={parentIds}
                    iconButton={!action?.showButtonLabel}
                    isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                    actionsMap={action?.actionsMap}
                    actionsKey={action?.actionsKey}
                    isChainMapped={action?.isChainMapped}
                    conditionKeyFromTable={action?.conditionActionMapKey}
                    index={index}
                    dataEntry={dataEntry}
                    actionIndex={action?.actionIndex}
                    actions={actions}
                  />
                )
              }
            </PermissibleRender>
          ))}
        {showEllipsisForActions && actions?.length > numberOfActionsToDisplayEllipsis && (
          <>
            <Box style={{ cursor: "pointer" }} onClick={handleClick}>
              <IconButton>
                <BXIcon
                  icon={ellipsisActionIcon?.icon}
                  url={ellipsisActionIcon?.url}
                  visibility={ellipsisActionIcon?.visibility}
                  fallBack='Dots'
                  size={20}
                />
              </IconButton>
            </Box>
            <Popover open={open} onClose={handleClose} x={x} y={y} minWidth={minWidth} maxWidth={maxWidth}>
              {actions?.slice(numberOfActionsToDisplayEllipsis, actions.length)?.map(action => (
                <PermissibleRender isAllowed key={action?.id} action={["VIEW"]} path={[path, action.label].join(".")}>
                  {({ permitted }) =>
                    permitted && (
                      <ActionButton
                        path={[path, action.label].join(".")}
                        viewName={viewName}
                        queryKeys={queryKeys}
                        pageId={pageId}
                        item={item}
                        disabled={disabled || !permitted}
                        key={action.id}
                        _key={`${action.id}-${item.__id}`}
                        tableAction={{
                          action,
                          label: action?.label,
                          iconConfig: action?.iconConfig,
                          icon:
                            (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                              ? action?.iconConfig?.url
                              : action?.iconConfig?.icon) || action?.icon,
                          type: action?.componentType || "Icon Button",
                          dataEntry,
                          condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                        }}
                        onStateChange={data => {
                          handleClose?.();
                          onStateChange(data);
                        }}
                        isUserInput={isUserInput}
                        views={views}
                        onSelectRow={onSelectRow}
                        tableId={tableId}
                        iconButton={!showLabelList}
                        fullWidth
                        withBorder={false}
                        variant='outlined'
                        __data={__data}
                        closeModal={closeModal}
                        parentIds={parentIds}
                        isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                        actionsMap={action?.actionsMap}
                        actionsKey={action?.actionsKey}
                        isChainMapped={action?.isChainMapped}
                        conditionKeyFromTable={action?.conditionActionMapKey}
                        dataEntry={dataEntry}
                        actionIndex={action?.actionIndex}
                        actions={actions}
                        fieldAlignment={fieldAlignment}
                        index={index}
                      />
                    )
                  }
                </PermissibleRender>
              ))}
            </Popover>
          </>
        )}

        {columnConfig.showInPopoverOnMobile && isMobile && (
          <>
            <Box style={{ cursor: "pointer" }} onClick={handleClick}>
              <IconButton>
                <BXIcon
                  icon={columnConfig.popoverIcon?.icon || "IconDotsVertical"}
                  color={open ? columnConfig.popoverOpenColor : columnConfig.popoverClosedColor}
                  size={20}
                />
              </IconButton>
            </Box>
            <Popover
              open={open}
              onClose={handleClose}
              x={x}
              y={y}
              minWidth={minWidth}
              maxWidth={maxWidth}
              popoverContentStyle={{
                display: "flex",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              style={{
                display: "flex",
                borderRadius: columnConfig?.popoverBorderRadius ? `${columnConfig.popoverBorderRadius}px` : "8px",
              }}
            >
              {actions?.map(action => (
                <PermissibleRender isAllowed key={action?.id} action={["VIEW"]} path={[path, action.label].join(".")}>
                  {({ permitted }) =>
                    permitted && (
                      <ActionButton
                        path={[path, action.label].join(".")}
                        viewName={viewName}
                        queryKeys={queryKeys}
                        pageId={pageId}
                        item={item}
                        disabled={disabled || !permitted}
                        key={action.id}
                        _key={`${action.id}-${item.__id}`}
                        tableAction={{
                          action,
                          label: action?.label,
                          iconConfig: action?.iconConfig,
                          icon:
                            (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                              ? action?.iconConfig?.url
                              : action?.iconConfig?.icon) || action?.icon,
                          type: action?.componentType || "Icon Button",
                          dataEntry,
                          condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                        }}
                        onStateChange={data => {
                          handleClose?.();
                          onStateChange(data);
                        }}
                        isUserInput={isUserInput}
                        views={views}
                        onSelectRow={onSelectRow}
                        tableId={tableId}
                        iconButton={true}
                        fullWidth
                        withBorder={false}
                        variant='outlined'
                        __data={__data}
                        closeModal={closeModal}
                        parentIds={parentIds}
                        isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                        actionsMap={action?.actionsMap}
                        actionsKey={action?.actionsKey}
                        isChainMapped={action?.isChainMapped}
                        conditionKeyFromTable={action?.conditionActionMapKey}
                        dataEntry={dataEntry}
                        actionIndex={action?.actionIndex}
                        actions={actions}
                        fieldAlignment={fieldAlignment}
                        index={index}
                      />
                    )
                  }
                </PermissibleRender>
              ))}
            </Popover>
          </>
        )}
      </>
    );
  return <></>;
};
