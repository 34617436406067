import { memo, useEffect, useState } from "react";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { sanitizeApiKey } from "src/utils/helpers";
import "src/views/pages/BuildX/FormBuilder/components/GoogleMapAutocomplete/GoogleMapAutocompleteStyles.css";
import { useMapsApiLoader } from "./GoogleMaps.helper";
import { MapComponent } from "./MapComponent";

interface CustomGoogleMapProps {
  enableMapTypeControl?: boolean;
  circleRadius?: number;
  enableCircleRadius?: boolean;
  enableFullscreenControl?: boolean;
  enableZoomControl?: boolean;
  enableStreetViewControl?: boolean;
  onChange: (location: { lat: number; lng: number } | null) => void;
  referenceLatAndLngValues: string;
  keepPinCentered?: boolean;
  googleMapApiKey: string;
  longitudeDefaultValue?: number;
  latitudeDefaultValue?: number;
  longitudeInputValue?: number;
  latitudeInputValue?: number;
  borderRadius?: string;
  pageId: string;
  info?: any;
}

export const CustomGoogleMap = memo<CustomGoogleMapProps>(props => {
  const {
    enableMapTypeControl,
    circleRadius,
    enableCircleRadius,
    enableFullscreenControl,
    enableZoomControl,
    enableStreetViewControl,
    onChange,
    referenceLatAndLngValues,
    keepPinCentered,
    googleMapApiKey,
    longitudeDefaultValue,
    latitudeDefaultValue,
    longitudeInputValue,
    latitudeInputValue,
    borderRadius,
    pageId,
    info,
  } = props;

  const localGoogleMapsApiKey = sanitizeApiKey(googleMapApiKey);
  const { isLoaded, hasApiKey } = useMapsApiLoader(localGoogleMapsApiKey);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(15);
  const { useReplaceDataPlaceholdersUseSelector } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const localReferenceLatAndLngValues = `{this.${referenceLatAndLngValues}.state.geometry.location}`;

  const resolvedLocalReferenceLatAndLngValues = useReplaceDataPlaceholdersUseSelector({
    queryString: localReferenceLatAndLngValues,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });

  const resolvedLongitudeDefaultValue = useReplaceDataPlaceholdersUseSelector({
    queryString: longitudeDefaultValue,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });

  const resolvedLatitudeDefaultValue = useReplaceDataPlaceholdersUseSelector({
    queryString: latitudeDefaultValue,
    viewsState,
    pageId,
    viewName: info?.viewName,
    env: currentApp?.env,
  });

  useEffect(() => {
    if (resolvedLocalReferenceLatAndLngValues) {
      const latitude =
        typeof resolvedLocalReferenceLatAndLngValues.lat === "function"
          ? resolvedLocalReferenceLatAndLngValues.lat()
          : resolvedLocalReferenceLatAndLngValues.lat;
      const longitude =
        typeof resolvedLocalReferenceLatAndLngValues.lng === "function"
          ? resolvedLocalReferenceLatAndLngValues.lng()
          : resolvedLocalReferenceLatAndLngValues.lng;

      if (latitude && longitude) {
        setSelectedLocation({ lat: latitude, lng: longitude });
        onChange?.({ lat: latitude, lng: longitude });
      }
      setZoomLevel(15);
    }
  }, [resolvedLocalReferenceLatAndLngValues]);

  useEffect(() => {
    if (resolvedLongitudeDefaultValue && resolvedLatitudeDefaultValue) {
      const lat = Number(resolvedLatitudeDefaultValue);
      const lng = Number(resolvedLongitudeDefaultValue);

      if (isFinite(lat) && isFinite(lng)) {
        const newLocation = {
          lat,
          lng,
        };
        if (newLocation) {
          setSelectedLocation(newLocation);
          onChange?.(newLocation);
        }
      }
    }
  }, [resolvedLongitudeDefaultValue, resolvedLatitudeDefaultValue]);

  useEffect(() => {
    if (longitudeInputValue && latitudeInputValue && !resolvedLongitudeDefaultValue && !resolvedLatitudeDefaultValue) {
      const lat = Number(latitudeInputValue);
      const lng = Number(longitudeInputValue);

      if (isFinite(lat) && isFinite(lng)) {
        const newLocation = {
          lat,
          lng,
        };
        if (newLocation) {
          setSelectedLocation(newLocation);
          onChange?.(newLocation);
        }
      }
    }
  }, [longitudeInputValue, latitudeInputValue]);

  if (!hasApiKey) {
    return <div style={{ padding: "10px", textAlign: "center" }}>Please provide a Google Maps API key.</div>;
  }

  if (hasApiKey && !isLoaded) {
    return <div style={{ padding: "10px", textAlign: "center" }}>Loading maps...</div>;
  }

  return (
    <>
      {isLoaded ? (
        <MapComponent
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          onChange={onChange}
          boundsPath={`${props["data-bx-key"]}.bounds`}
          pageId={pageId}
          viewName={info?.viewName}
          zoomLevel={zoomLevel}
          isApiLoaded={isLoaded}
          options={{
            enableMapTypeControl,
            enableFullscreenControl,
            enableZoomControl,
            enableStreetViewControl,
            keepPinCentered,
            circleRadius,
            enableCircleRadius,
            borderRadius,
          }}
        />
      ) : (
        <div style={{ padding: "10px", textAlign: "center" }}>Please provide a valid Google Maps API key.</div>
      )}
    </>
  );
});
